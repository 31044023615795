.header {
  position: sticky;
  top: 0;
  z-index: 1;
  transition: all 4.5s ease;
  background-color: var(--dynamic-background);
  height: 107px;
  width: 100%;
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;

  &__logo {
    width: 237px;
  }

  &__menu-btn {
    color: #fff;
    text-align: right;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
}
