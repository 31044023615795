.project-card{
  max-width: 100%;
  width: 100%;
  position: relative;

  .text{
    position: relative;

    h2{
      font-size: 24px;
      font-weight: 500;
      color: #454242;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      display: inline-block;
      letter-spacing: .03em;
    }
  }

  img{
    display: block;
    height: 563px;
    width: 100%;
    max-width: 100%;
    display: block;
    cursor: pointer;
    object-fit: cover;
    transition: all 0.45s ease;
    will-change: auto;
    backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
  }

  picture{
    display: block;
    height: 563px;
    width: 100%;
    position: relative;
    transition: all 0.45s ease;
    will-change: auto;
    backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      cursor: pointer;
    @media (hover: hover) {
      &::after{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        background-color: rgba(22, 22, 22, 0.5);
        mix-blend-mode: color;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 0.25s ease;
      }
    }
  }
  @media (hover: hover) {
    &:hover{
      picture, img{
        transition-delay: 0.3s;
        transform-origin: center;
        transform:scale(1.015);
        transition: all 0.25s ease;
      }

      picture::after{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        background-color: rgba(22, 22, 22, 0);
        mix-blend-mode: color;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 0.25s ease;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    picture, img{
      height: 250px;
      object-fit: contain;
    }
  }
}
