.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--grid-gap);
  margin: 0 auto;
  max-width: 1296px;

  &--narrow {
    width: 782px;
    max-width: 90vw;
  }

  &--2 {
    grid-template-columns: 1fr 1fr;
  }

  &--3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &--4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &--5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  &--6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  h1 {
    margin: 0;
    line-height: 1;
  }

  div {
    white-space: pre-line;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    max-width: 90vw;

    h1 {
      padding-left: 0;
    }
  }
}
