.footer{
  min-height: 400px;
  background-color: var(--footer-background);
  color: var(--footer-color);
  margin-top: 64px;

  p{
    color: #fff;
  }
}
