.nav {
  position: fixed;
  width: 432px;
  height: 100vh;
  right: -432px;
  top: 0;
  background-color: #000;
  z-index: 11;
  opacity: 0;
  transition: all 1s cubic-bezier(0.08, 0.705, 0.005, 0.99);
  -webkit-transition: all 1s cubic-bezier(0.08, 0.705, 0.005, 0.99);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: auto;

  .nav__close {
    position: absolute;
    top: 45px;
    right: 36px;
    font-size: 18px;
    font-weight: 500;
    color: #5abcbd;
    cursor: pointer;
    letter-spacing: 0.1em;
    background-color: transparent;
    border: none;
  }

  &.nav--open {
    right: 0;
    opacity: 1;

    .nav__close {
      pointer-events: all;
    }
  }

  .nav__content {
    padding: 0 35px;
    margin: 107px auto;
  }

  .nav__content__cell {
    padding: 0.35rem;
    position: relative;
    box-sizing: border-box;
    margin-right: 30px;
    flex: 1;
    transition: all 1.25s cubic-bezier(0.08, 0.705, 0.005, 0.99);
    -webkit-transition: all 1.25s cubic-bezier(0.08, 0.705, 0.005, 0.99);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    flex: 0 1 calc(50% - 30px);
    z-index: inherit;

    h2 {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 50px;
      padding-left: 80px;
      position: relative;

      &::before {
        content: " ";
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        width: 70px;
        height: 1px;
        background: #fff;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      padding-left: 80px;
      z-index: inherit;

      li {
        color: #fff;
        font-size: 16px;
        margin: 0 0 30px;
        padding: 0;
        font-weight: 200;
        z-index: inherit;
      }
    }
  }
}
