.project-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 36px;
  gap: var(--grid-gap);

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
